import React from 'react';
import styles from './SupportedQuestions.module.css';

const SupportedQuestions = () => {
  const questions = [
    "Welche Kaffee-Variationen gibt es überhaupt und wodurch unterscheiden sie sich?",
    "Welche Bohnensorte passt zu mir?",
    "Was gibt es beim Bohnenkauf und der Lagerung von Kaffeebohnen zu beachten?",
    "Wie stelle ich den Mahlgrad an meiner Kaffeemühle richtig ein?",
    "Wie fest muss ich Tampern?",
    "Der Espresso schmeckt sauer oder bitter, was tun?",
    "Wie kann ich die perfekte Crema erzeugen?",
    "Mit der Siebträgermaschine Milch schäumen, wie geht das?",
    "Espressomaschine reinigen - was gibt's zu beachten?",
    "Welchen Einfluss hat die Temperatur auf den Kaffee?",
    "Wie behalte ich den Überblick über mehrere Kaffeebohnen und Einstellungsparameter?",
    "und viele mehr ...",
  ];

  return (
    <div id="questions" className={styles.container}>
      <section className={styles.section}>
        <h2 className={styles.title}>Bei welchen Fragen / Problemen hilft Dir Ciao Barista?</h2>
        <ul className={styles.questionList}>
          {questions.map((question, index) => (
            <React.Fragment key={index}>
              <li className={styles.questionItem}>{question}</li>
              {index < questions.length - 1 && <hr className={styles.divider} />}
            </React.Fragment>
          ))}
        </ul>
      </section>
    </div>
  );
};

export default SupportedQuestions;
