import React from 'react';
import styles from './Hero.module.css';

import appImg from '../assets/images/App.gif';
import appStore from '../assets/images/appstore.png';
import googlePlay from '../assets/images/googleplay.png';

const Hero = () => {
  return (
    <div className={styles.background}>
      <div className={styles.container}>
        <section className={styles.section}>
          <div className={styles.left}>
            <h1 className={styles.title}>Ciao Barista</h1>
            <p className={styles.description}>Spielend leicht perfekten Kaffee mit deiner Siebträgermaschine zubereiten</p>
            <div className={styles.storeIcons}>
              <img src={appStore} alt="App Store" className={styles.icon} />
              <img src={googlePlay} alt="Google Play" className={styles.icon} />
            </div>
          </div>
          <div className={styles.right}>
            <img src={appImg} alt="App Preview" className={styles.image} />
          </div>
        </section>
      </div>
    </div>
  );
};

export default Hero;
