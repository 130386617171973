import React, { useEffect, useState } from 'react';
import styles from './Datenschutz.module.css';

const Datenschutz = () => {
  const [text, setText] = useState('');

  useEffect(() => {
    fetch('./datenschutzerklaerung.txt')
      .then(response => response.text())
      .then(data => setText(data));
  }, []);

  return (
    <div className={styles.container}>
      <h2 className={styles.title}>Datenschutz</h2>
      <pre className={styles.content}>{text}</pre>
    </div>
  );
};

export default Datenschutz;
