import React from 'react';
import styles from './UseCases.module.css';

import machine from '../assets/logos/coffee-machine.png';
import espresso from '../assets/logos/espresso.png';
import barista from '../assets/logos/barista.png';

const UseCases = () => {
  return (
    <div id="usecases" className={styles.background}>
      <div className={styles.container}>
        <section className={styles.gallerySection}>
          <h2 className={styles.galleryTitle}>Für wen ist Ciao Barista?</h2>
          <div className={styles.gallery}>
            <div className={styles.galleryItem}>
             <div className={styles.galleryImage}>
                <img src={machine} alt="Espresso machine" />
              </div>
              <p className={styles.galleryText}>
                Neue Siebträgermaschine aber noch keinen Plan wie Barista geht? Ciao Barista hilft dir dabei die Grundlage des Handwerks zu erlernen.
              </p>
            </div>
            <div className={styles.galleryItem}>
              <div className={styles.galleryImage}>
                <img src={espresso} alt="Espresso" />
              </div>
              <p className={styles.galleryText}>
                Der perfekte Kaffee möchte dir nicht gelingen? Ciao Barista bringt dich aufs nächste Level und hilft dir das Beste aus deiner Maschine herauszuholen.
              </p>
            </div>
            <div className={styles.galleryItem}>
              <div className={styles.galleryImage}>
                <img src={barista} alt="Barista" />
              </div>
              <p className={styles.galleryText}>
                Bereits routinierter Hobby-Barista? Mit Ciao Barista behälst du den Überblick über verschiedene Bohnen und Einstellungs&shy;details (z.B: Mahlgrad).
              </p>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default UseCases;
