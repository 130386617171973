import React, { useState } from 'react';

import styles from './Header.module.css';
import logo from '../assets/logos/logo.png';

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <header className={styles.header}>
      <div className={styles.container}>
        <a href="/" className={styles.logoLink}>
          <img src={logo} alt="Logo" className={styles.logo} />
        </a>
        <button className={styles.hamburger} onClick={toggleMenu}>
          &#9776;
        </button>
        <nav className={`${styles.nav} ${isOpen ? styles.show : ''}`}>
          <a href="/#features" className={styles.navItem}>Features</a>
          <a href="/#usecases" className={styles.navItem}>Anwendungsfälle</a>
          <a href="/#questions" className={styles.navItem}>Fragen</a>
          <a href="/#download" className={styles.navItem}>App downloaden</a>
          <a href="/#contact" className={styles.navItem}>Kontakt</a>
        </nav>
      </div>
    </header>
  );
};

export default Header;
