import React from 'react';
import styles from './Gallery.module.css';

import wissenImg from '../assets/images/Wissen.png';
import processImg from '../assets/images/Prozess.png';
import bewertenImg from '../assets/images/Bewerten.png';
import journalImg from '../assets/images/Journal.png';
import analysisImg from '../assets/images/Analysen.png';

const Gallery = () => {
  return (
      <div id="features" className={styles.container}>
        <section className={styles.gallerySection}>
          <h2 className={styles.galleryTitle}>Features</h2>
          <div className={styles.gallery}>
            <div className={styles.galleryItem}>
              <img src={processImg} alt="Prozess App Screenshot" className={styles.galleryImage} />
              <p className={styles.galleryText}>
                Lass dich durch den Zubereitungs&shy;prozess begleiten und dokumentiere Parameter wie Bohnen&shy;auswahl, Mahlgrad, usw.
              </p>
            </div>
            <div className={styles.galleryItem}>
              <img src={wissenImg} alt="Wissen App Screenshot" className={styles.galleryImage} />
              <p className={styles.galleryText}>
                Du willst noch über die Hintergründe der Kaffeezubereitung lernen? Im Bereich Wissen bekommst du das "Barista 101".
              </p>
            </div>
            <div className={styles.galleryItem}>
              <img src={bewertenImg} alt="Bewertung App Screenshot" className={styles.galleryImage} />
              <p className={styles.galleryText}>
                Wie war dein Kaffee? Bewerte und kommentiere deine Zubereitungen mit deinen persönlichen Notizen.
              </p>
            </div>
            <div className={styles.galleryItem}>
              <img src={journalImg} alt="Journal App Screenshot" className={styles.galleryImage} />
              <p className={styles.galleryText}>
                Gespeichert werden alle Bezüge im Journal, deinem digitalen Kaffee-Gedächtnis.
              </p>
            </div>
            <div className={styles.galleryItem}>
              <img src={analysisImg} alt="Analysen App Screenshot" className={styles.galleryImage} />
              <p className={styles.galleryText}>
                Show me the data - die Ciao Barista Analysen zeigen dir welche Bohnen und Einstellungen dir am besten schmecken.
              </p>
            </div>
          </div>
        </section>
      </div>
  );
};

export default Gallery;
