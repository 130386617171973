import React from 'react';
import styles from './DownloadNow.module.css';

import espresso from '../assets/images/espresso.png';
import appStore from '../assets/images/appstore.png';
import googlePlay from '../assets/images/googleplay.png';

const DownloadNow = () => {
  return (
    <div className={styles.background}>
      <div className={styles.container}>
        <h2 className={styles.title}>Jetzt kostenlos downloaden!</h2>
        <section id="download" className={styles.section}>
          <h1 className={styles.brand}>Ciao Barista</h1>
          <img src={espresso} alt="Espresso" className={styles.espresso} />
          <h1 className={styles.callToAction}>Jetzt kostenlos downloaden und perfekten Kaffee genießen</h1>
          <div className={styles.storeIcons}>
            <img src={appStore} alt="App Store" className={styles.icon} />
            <img src={googlePlay} alt="Google Play" className={styles.icon} />
          </div>
        </section>
      </div>
    </div>
  );
};

export default DownloadNow;
