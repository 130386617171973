import React from 'react';
import styles from './Footer.module.css';
import { Link } from 'react-router-dom';
import logo from '../assets/logos/logo.png';
import mailIcon from '../assets/logos/mail.png';
import instagramIcon from '../assets/logos/instagram.png';
import appStoreIcon from '../assets/images/appstore.png';
import googlePlayIcon from '../assets/images/googleplay.png';

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <div id="contact" className={styles.container}>
         <a href="/" className={styles.logoLink}>
          <img src={logo} alt="Logo" className={styles.logo} />
        </a>
        <nav className={styles.nav}>
          <div className={styles.iconRow}>
            <a href="mailto:ciao@ciaobarista.com" className={styles.navItem}>
              <img src={mailIcon} alt="Email" />
            </a>
            <Link to="https://www.instagram.com/ciaobarista" className={styles.navItem}>
              <img src={instagramIcon} alt="Instagram" />
            </Link>
          </div>
          <div className={styles.iconRow}>
            <Link to="https://www.apple.com/app-store/" className={styles.navItem}>
              <img src={appStoreIcon} alt="App Store" />
            </Link>
            <Link to="https://play.google.com/store" className={styles.navItem}>
              <img src={googlePlayIcon} alt="Google Play" />
            </Link>
          </div>
        </nav>
      </div>
      <div>
        <p>
          <Link to="/impressum" className={styles.navItem}>Impressum</Link> | 
          <Link to="/datenschutz" className={styles.navItem}>Datenschutzerklärung</Link>
        </p>
      </div>
    </footer>
  );
};

export default Footer;
